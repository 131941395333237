import { useStaticQuery, graphql } from 'gatsby'

const useSiteMetaData = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            keywords
            footerLinks {
              iconType
              title
              url
            }
          }
        }
      }
    `
  )
  return site.siteMetadata
}

export default useSiteMetaData
