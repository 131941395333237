import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  margin: var(--gap-size);
  width: 100%;

  @media screen and (min-width: 1024px) {
    display: grid;
    grid-gap: var(--gap-size);
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
`

const Grid = ({ children }) => {
  return <Container>{children}</Container>
}

export default Grid
