import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  position: relative;
`

const Button = styled.button`
  align-items: center;
  background-color: ${props => props.theme.colors.menuBgColor};
  border: 0;
  clip-path: url(#squareClip);
  color: ${props => props.theme.colors.white};
  cursor: pointer;
  display: flex;
  height: 60px;
  justify-content: center;
  opacity: 0.9;
  padding: 0;
  pointer-events: all;
  position: relative;
  transition: all 500ms ease;
  width: 60px;

  img {
    width: 100%;
    height: 100%;
    position: relative;
  }

  &:hover,
  &:focus {
    background-color: ${props => props.theme.colors.menuHoverColor};
    opacity: 1;
    transform: translateY(-2px);
  }
`

const Profile = () => (
  <Container>
    <Button onClick={() => console.log('woop')}>
      <svg xmlns='http://www.w3.org/2000/svg' width='32' height='30' viewBox='0 0 32 30'>
        <defs>
          <linearGradient id='a' x1='0%' x2='100%' y1='93.945%' y2='6.055%'>
            <stop offset='0%' stopColor='#F23175'></stop>
            <stop offset='100%' stopColor='#FCB46D'></stop>
          </linearGradient>
        </defs>
        <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
          <g fill='url(#a)' fillRule='nonzero'>
            <path d='M16 0c6.627 0 11.998 5.253 11.998 11.731 0 3.56-1.636 6.844-4.347 9.038 3.696 1.729 6.679 4.472 8.349 7.802-.478.49-.98.956-1.502 1.4-1.699-3.505-5.165-6.585-9.246-8.07l-1.964-.715 1.801-1.044c3.014-1.75 4.901-4.92 4.901-8.41 0-5.399-4.473-9.773-9.99-9.773-5.516 0-9.989 4.374-9.989 9.772 0 3.492 1.887 6.662 4.9 8.411l1.801 1.045-1.963.715C6.513 23.442 3.13 26.382 1.54 30A21.8 21.8 0 010 28.57c1.616-3.22 4.457-5.889 7.984-7.624l.367-.177-.268-.223c-2.551-2.19-4.08-5.373-4.08-8.815C4.003 5.253 9.373 0 16 0z'></path>
          </g>
        </g>
      </svg>
    </Button>
  </Container>
)

export default Profile
