import React from 'react'
import styled from 'styled-components'
import { Logo } from '..'
import Profile from './Profile'

const Container = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
`

const Divider = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  padding: 30px;

  &:nth-child(2) {
    align-items: flex-start;
    justify-content: flex-end;
  }
`

const SopmeText = styled.span`
  display: none;
  font-size: 20px;
  font-weight: 800;
  margin-left: 20px;
  pointer-events: all;

  @media screen and (min-width: 1024px) {
    display: block;
  }

  @media screen and (min-width: 1366px) {
    font-size: 24px;
  }
`
const SubText = styled(SopmeText)`
  border-left: 1px solid #fff;
  font-weight: 400;
  margin-left: 10px;
  padding-left: 10px;
`

const Header = () => {
  return (
    <Container>
      <Divider>
        <Logo />
        <SopmeText>sopme</SopmeText>
        <SubText>something for s&box site</SubText>
      </Divider>
      <Divider>
        <Profile />
      </Divider>
    </Container>
  )
}

export default Header
