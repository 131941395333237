import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const Container = styled(Link)`
  align-items: center;
  display: flex;
  height: 60px;
  justify-content: center;
  pointer-events: all;
  position: relative;
  text-decoration: none;
  transition: all 500ms ease;
  width: 60px;

  &:before,
  &:after {
    content: none;
  }

  &:hover,
  &:focus {
    transform: translateY(-2px);
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
`

const Logo = ({ className }) => (
  <Container to='/' className={className}>
    <span className='sr-only'>sopme logo</span>
    <svg xmlns='http://www.w3.org/2000/svg' width='60' height='60' viewBox='0 0 60 60'>
      <defs>
        <linearGradient id='linearGradient-1' x1='0%' x2='100%' y1='100%' y2='0%'>
          <stop offset='0%' stopColor='#FF005E'></stop>
          <stop offset='100%' stopColor='#FFAB57'></stop>
        </linearGradient>
      </defs>
      <path
        fill='url(#linearGradient-1)'
        d='M55.684.797a414.452 414.452 0 00-51.367 0C2.496.912.912 2.496.797 4.317a414.448 414.448 0 000 51.366c.115 1.821 1.699 3.405 3.52 3.52a414.452 414.452 0 0051.367 0c1.82-.115 3.404-1.699 3.52-3.52a414.554 414.554 0 000-51.366c-.116-1.821-1.7-3.405-3.52-3.52z'
      ></path>
      <path
        fill='#191C1F'
        d='M22.875 11.73c6.185-3.56 14.094-1.448 17.665 4.719a12.826 12.826 0 011.641 4.883c.064.52-.339 1.211-1.13.942a12.949 12.949 0 00-11.71.621 12.88 12.88 0 00-6.469 11.168 12.855 12.855 0 01-4.731-4.721c-3.571-6.167-1.452-14.052 4.734-17.612zm14.094 36.54c-6.185 3.56-14.095 1.448-17.666-4.719a12.826 12.826 0 01-1.64-4.883c-.064-.52.339-1.211 1.13-.942a12.949 12.949 0 0011.71-.621 12.88 12.88 0 006.468-11.167 12.855 12.855 0 014.732 4.72c3.57 6.167 1.452 14.052-4.734 17.612z'
      ></path>
    </svg>
  </Container>
)

export default Logo
