import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

const Container = styled.figure`
  display: flex;
  justify-content: center;
  margin: 0;
  position: relative;
  width: 1024px;

  &::before {
    background: linear-gradient(0deg, #1a1c1f 0%, transparent 75%);
    bottom: 0;
    content: '';
    height: 200px;
    position: absolute;
    width: 100%;
    z-index: 1;
  }

  @media screen and (min-width: 1024px) {
    width: 1920px;
  }
`

const Title = styled.h1`
  color: ${props => props.theme.colors.white};
  font-size: 4vw;
  font-weight: 800;
  position: absolute;
  top: 50%;
  z-index: 1;
`

const BgImg = styled(GatsbyImage)`
  width: 100%;
`

const Banner = ({ title, image }) => (
  <Container>
    <Title>{title}</Title>
    {image && <BgImg image={image} alt='' />}
  </Container>
)

export default Banner
