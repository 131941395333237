import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import useSiteMetadata from '../../hooks/useSiteMetadata'
import { Icon } from '..'

const Container = styled.footer`
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  width: 100%;

  a {
    align-items: center;
    display: flex;

    svg {
      fill: #fff;
      height: 3.2rem;
      margin-right: 0.5rem;
      width: 3.2rem;
    }
  }
`

const Footer = () => {
  const { footerLinks } = useSiteMetadata()

  return (
    <Container>
      <Link to='/'>sopme.com</Link>
      {footerLinks.map(item => (
        <a href={item.url} target='_blank' rel='noreferrer' key={item.title}>
          <Icon type={item.iconType} />
          {item.title}
        </a>
      ))}
    </Container>
  )
}

export default Footer
