import React from 'react'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const Item = styled.div`
  border-radius: 30px;
  grid-row: span 1;
  overflow: hidden;
  transform: translateZ(0);

  &:nth-of-type(3n + 2) {
    grid-row: span 2;
  }
`

const GridItem = ({ image }) => (
  <Item>
    <GatsbyImage
      image={getImage(image.localFile)}
      alt={image.fileName}
      style={{ height: '100%' }}
    />
  </Item>
)

export default GridItem
