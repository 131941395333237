import React from 'react'
import Helmet from 'react-helmet'
import styled, { ThemeProvider } from 'styled-components'
import useSiteMetadata from '../../hooks/useSiteMetadata'
import { Header, Footer } from '..'
import GlobalStyle from './GlobalStyle'
import theme from './theme'

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
  width: 100%;
`

const Content = styled.article`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 100%;

  @media screen and (min-width: 1440px) {
    max-width: 1440px;
    overflow: visible;
  }
`

const Layout = ({ children }) => {
  const url = 'https://sopme.com/'
  const { title, description, author, keywords } = useSiteMetadata()
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <meta charSet='utf-8' />
        <html lang='en' />
        <title>{title}</title>
        <meta name='description' content={description} />
        <meta name='keywords' content={keywords} />
        <meta property='og:image' content={url + '/og.jpg'} />
        <meta property='og:title' content={title} />
        <meta property='og:description' content={description} />
        <meta property='og:url' content={url} />
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:creator' content={author} />
        <meta name='apple-mobile-web-app-status-bar-style' content='black-translucent' />
      </Helmet>
      <GlobalStyle />
      <Container>
        <Content>
          <Header />
          {children}
        </Content>
        <Footer />
      </Container>
    </ThemeProvider>
  )
}

export default Layout
