import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  :root {
    --gap-size: 24px;
  }

  html {
    font-size: 10px;
  }

  body {
    align-items: center;
    background-color: #191C1F;
    color: #fff;
    font-family: "Poppins", arial;
    font-size: 1.6rem;
    margin: 0;

    * {
      box-sizing: border-box;
    }
  }

  button {
    font-size: 1.6rem;
  }

  a {
    color: #ddd;
    position: relative;
    text-decoration: none;
    transition: all 300ms ease;

    &::before {
      background-color: #fff;
      content: '';
      height: 0.2rem;
      position: absolute;
      top: 100%;
      transition: all 300ms ease;
      width: 0%;
    }
    
    &:hover {
      color: #fff;
      &::before {
        width: 100%;
      }
    }
  }

  img {
    display: block;
    width: 100%;
  }
`

export default GlobalStyle
