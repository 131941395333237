module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"sopme","short_name":"sopme","start_url":"/","background_color":"#191C1F","theme_color":"#191C1F","display":"standalone","icon":"static/logo512.png","icons":[{"src":"logo512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8acab0c4e6b8ff9f0c273390b1687511"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
