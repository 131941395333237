const colors = {
  bgColor: '#191C1F',
  menuBgColor: '#22272c',
  menuHoverColor: '#2C343C',
  white: '#fff'
}

const fontSizes = ['1rem', '1.4rem', '1.8rem']

const breakpoints = ['320px', '460px', '960px', '1280px', '1280px', '1500px']

const theme = {
  breakpoints,
  colors,
  fontFamily:
    '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif, ' +
    '"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"', // emoji fonts
  fontSizes
}

export default theme
