import React from 'react'

const Icon = ({ type }) => {
  switch (type) {
    case 'sopme':
      return (
        <svg xmlns='http://www.w3.org/2000/svg' width='26' height='35' viewBox='0 0 26 35'>
          <defs>
            <clipPath id='squareClip'>
              <path d='M59.204 4.317c-.116-1.821-1.7-3.405-3.52-3.52a414.452 414.452 0 00-51.367 0C2.496.912.912 2.496.797 4.317a414.448 414.448 0 000 51.366c.115 1.821 1.699 3.405 3.52 3.52a414.452 414.452 0 0051.367 0c1.82-.115 3.404-1.699 3.52-3.52a414.554 414.554 0 000-51.366'></path>
            </clipPath>
          </defs>
          <path
            fillRule='nonzero'
            d='M20.088 9.393c-.682-.868-1.573-1.558-2.674-2.07-1.1-.51-2.162-.767-3.185-.767-.527 0-1.062.047-1.604.14a4.142 4.142 0 00-1.465.535 3.621 3.621 0 00-1.093 1.023c-.294.418-.441.953-.441 1.604 0 .558.116 1.023.348 1.395.233.372.574.697 1.023.976.45.28.984.535 1.604.768.62.232 1.318.472 2.093.72 1.116.373 2.278.783 3.487 1.233 1.21.45 2.31 1.046 3.302 1.79a9.398 9.398 0 012.465 2.767c.65 1.1.976 2.472.976 4.115 0 1.891-.349 3.526-1.046 4.906-.698 1.38-1.635 2.518-2.814 3.418-1.178.899-2.526 1.565-4.045 1.999a17.011 17.011 0 01-4.696.651c-2.357 0-4.635-.41-6.836-1.232-2.201-.822-4.03-1.992-5.487-3.511l5.208-5.301c.806.992 1.868 1.821 3.185 2.488 1.318.666 2.627 1 3.93 1 .589 0 1.162-.062 1.72-.186a4.292 4.292 0 001.465-.605 3.027 3.027 0 001-1.116c.248-.465.372-1.023.372-1.674 0-.62-.155-1.147-.465-1.581-.31-.434-.752-.83-1.326-1.186-.573-.356-1.286-.682-2.139-.976l-.66-.225c-.684-.23-1.432-.473-2.246-.729a27.774 27.774 0 01-3.092-1.209 10.35 10.35 0 01-2.697-1.79 8.291 8.291 0 01-1.907-2.65c-.48-1.039-.72-2.302-.72-3.79 0-1.829.371-3.394 1.116-4.697a9.701 9.701 0 012.929-3.208C6.882 1.581 8.246.968 9.765.581A18.553 18.553 0 0114.368 0c1.86 0 3.76.341 5.697 1.023 1.937.682 3.634 1.69 5.091 3.022l-5.068 5.348z'
          ></path>
        </svg>
      )

    case 'discord':
      return (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 245 240'>
          <path
            d='M104.4 103.9c-5.7 0-10.2 5-10.2 11.1s4.6 11.1 10.2 11.1c5.7 0 10.2-5 10.2-11.1.1-6.1-4.5-11.1-10.2-11.1zm36.5 0c-5.7 0-10.2 5-10.2 11.1s4.6 11.1 10.2 11.1c5.7 0 10.2-5 10.2-11.1s-4.5-11.1-10.2-11.1z'
            className='st0'
          ></path>
          <path
            d='M189.5 20h-134C44.2 20 35 29.2 35 40.6v135.2c0 11.4 9.2 20.6 20.5 20.6h113.4l-5.3-18.5 12.8 11.9 12.1 11.2 21.5 19V40.6c0-11.4-9.2-20.6-20.5-20.6zm-38.6 130.6s-3.6-4.3-6.6-8.1c13.1-3.7 18.1-11.9 18.1-11.9-4.1 2.7-8 4.6-11.5 5.9-5 2.1-9.8 3.5-14.5 4.3-9.6 1.8-18.4 1.3-25.9-.1-5.7-1.1-10.6-2.7-14.7-4.3-2.3-.9-4.8-2-7.3-3.4-.3-.2-.6-.3-.9-.5-.2-.1-.3-.2-.4-.3-1.8-1-2.8-1.7-2.8-1.7s4.8 8 17.5 11.8c-3 3.8-6.7 8.3-6.7 8.3-22.1-.7-30.5-15.2-30.5-15.2 0-32.2 14.4-58.3 14.4-58.3 14.4-10.8 28.1-10.5 28.1-10.5l1 1.2c-18 5.2-26.3 13.1-26.3 13.1s2.2-1.2 5.9-2.9c10.7-4.7 19.2-6 22.7-6.3.6-.1 1.1-.2 1.7-.2 6.1-.8 13-1 20.2-.2 9.5 1.1 19.7 3.9 30.1 9.6 0 0-7.9-7.5-24.9-12.7l1.4-1.6s13.7-.3 28.1 10.5c0 0 14.4 26.1 14.4 58.3 0 0-8.5 14.5-30.6 15.2z'
            className='st0'
          ></path>
        </svg>
      )

    default:
      return <div>hi</div>
  }
}

export default Icon
